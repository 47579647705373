<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M11.0017 19.5865C11.5531 20.1378 12.447 20.1378 12.9983 19.5865C13.5496 19.0352 13.5496 18.1413 12.9983 17.59L8.82008 13.4118L18.5882 13.4118C19.3679 13.4118 20 12.7797 20 12C20 11.2203 19.3679 10.5882 18.5882 10.5882L8.82008 10.5882L12.9983 6.41003C13.5496 5.8587 13.5496 4.96482 12.9983 4.4135C12.447 3.86217 11.5531 3.86217 11.0017 4.4135L4.41673 10.9985C4.40634 11.0088 4.3961 11.0193 4.38604 11.03C4.2678 11.1549 4.17673 11.2961 4.11281 11.4461C4.0402 11.6162 4 11.8034 4 12C4 12.1975 4.04056 12.3856 4.11381 12.5563C4.17602 12.7015 4.26372 12.8385 4.37694 12.9603C4.39019 12.9746 4.40373 12.9886 4.41756 13.0023L11.0017 19.5865Z"
    />
  </svg>
</template>
